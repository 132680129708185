import CheckboxSelectAll from "../checkbox-select-all"

export default class extends CheckboxSelectAll {
  bulkResearchFlagUpdate(event) {
    event.preventDefault()

    let data = new FormData()
    this.checked.forEach((checkbox) => data.append("ids[]", checkbox.value))
    data.append('research_flag', event.target.dataset.researchFlag)
    Rails.ajax({
      url: '/bulk/job_codes',
      type: "PUT",
      data: data
    })
    
    location.reload()
  }
}
