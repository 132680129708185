// controllers/dependent_select_controller.js

// Required data-attributes:
// * Define the source and target models. This assumes a nested relationship: /:source_route/:id/:target_route
//   * data-dependent-select-source-route-part-value
//   * data-dependent-select-target-route-part-value
//
// * Define the attribute to use for the display fields. For the value, id is used.
//  * data-dependent-select-display-attribute-value
//
// * Add to source select:
//   * 'data-dependent-select-target': 'source'
//   * 'data-action': 'change->dependent-select#populateDependentSelect'
//
// * Add to dependent select:
//   *'data-dependent-select-target': 'dependent'


import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source", "dependent"]
  static values = {
    displayAttribute: String,
    sourceRoutePart: String,
    targetRoutePart: String
  }

  populateDependentSelect(event) {
    Rails.ajax({
      type: 'get',
      url: `/${this.sourceRoutePartValue}/${event.target.value}/${this.targetRoutePartValue}`,
      format: 'json',
      success: (data) => {
        this.clearDependentSelect()
        this.updateDependentSelect(data)
      }
    })
  }

  clearDependentSelect() {
    while (this.dependentTarget.firstChild) {
      this.dependentTarget.removeChild(this.dependentTarget.firstChild)
    }
  }

  updateDependentSelect(data) {
    this.includeBlank()

    // this works if the target route shares the model name
    data.forEach(item => {
      const option = document.createElement("option");
      option.value = item.id;
      option.innerHTML = item[this.displayAttributeValue];
      this.dependentTarget.appendChild(option);
    });
  }

  includeBlank() {
    const option = document.createElement("option");
    option.value = '';
    option.innerHTML = 'All';
    this.dependentTarget.appendChild(option);
  }
}
