// Bootstrap 4
// <div class="input-group">
//   <%= f.password_field(:password, autocomplete: :off, data: {"password-visibility-target" => "input"}) %>
//   <div class="input-group-append">
//     <button class="input-group-text" data-action="password-visibility#toggle" type="button">
//       <i class="fa fa-eye-slash" data-password-visibility-target="icon"></i>
//       <i class="hidden fa fa-eye" data-password-visibility-target="icon"></i>
//     </button>
//   </div>
// </div>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'icon']
  static classes = ['d-none']

  connect() {
    this.hidden = this.inputTarget.type === 'password';
    this.class = this.hasHiddenClass ? this.hiddenClass : 'd-none';
  }

  toggle(e) {
    e.preventDefault();
    this.inputTarget.type = this.hidden ? 'text' : 'password';
    this.hidden = !this.hidden;
    this.iconTargets.forEach(icon => icon.classList.toggle(this.class));
  }
}
